import React, { useState } from 'react';
import FileUpload from './FileUpload';
import { Table, Button } from 'react-bootstrap';
import { compareFiles, getFileDetails } from './cosineSimilarity';
import CosineSimilarityChart from './CosineSimilarityChart';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  const [files, setFiles] = useState([]);
  const [fileDetails, setFileDetails] = useState([]);
  const [scores, setScores] = useState([]);
  const [show, setShow] = useState(false);
  const [chartData1, setChartData1] = useState([]);
  const [chartData2, setChartData2] = useState([]);
  const [file1, setFile1] = useState('');
  const [file2, setFile2] = useState('');

  const handleFilesUploaded = (uploadedFiles) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles, ...uploadedFiles];
      const uniqueFiles = Array.from(new Set(newFiles.map(file => file.name)))
        .map(name => newFiles.find(file => file.name === name));
      return uniqueFiles.slice(0, 5); // Ensure the number of files is between 2 and 5
    });
  };

  const calculateFileDetailsAndScores = async () => {
    const details = await Promise.all(files.map(file => getFileDetails(file)));
    setFileDetails(details);

    const newScores = [];
    for (let i = 0; i < files.length; i++) {
      for (let j = i + 1; j < files.length; j++) {
        const { cosineSimilarity, alignedData1, alignedData2 } = await compareFiles(files[i], files[j]);
        newScores.push({
          file1: files[i].name,
          file2: files[j].name,
          score: cosineSimilarity.toFixed(2),
          alignedData1,
          alignedData2
        });
      }
    }
    setScores(newScores);
  };

  const handleShowChart = (score) => {
    setChartData1(score.alignedData1);
    setChartData2(score.alignedData2);
    setFile1(score.file1);
    setFile2(score.file2);
    setShow(true);
  };

  return (
    <div className="container mt-5">
      <h1>上传色谱仪测量数据（TXT文件）</h1>
      <FileUpload onFilesUploaded={handleFilesUploaded} />
      {files.length > 0 && (
        <div className="mt-3">
          <h2>已上传文件：</h2>
          <ul>
            {files.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))}
          </ul>
          <button
            className="btn btn-primary mt-3"
            onClick={calculateFileDetailsAndScores}
            disabled={files.length < 2}
          >
            开始分析！
          </button>
        </div>
      )}
      {fileDetails.length > 0 && (
        <Table striped bordered hover className="mt-3">
          <thead>
            <tr>
              <th>香水数据</th>
              <th>剔除的噪音数据</th>
              <th>参与计算的行数</th>
            </tr>
          </thead>
          <tbody>
            {fileDetails.map((detail, index) => (
              <tr key={index}>
                <td>{detail.fileName}</td>
                <td>
                  {(detail.removedData && detail.removedData.length > 0) ? (
                    detail.removedData.map((row, i) => (
                      <div key={i}>{row.join(', ')}</div>
                    ))
                  ) : (
                    <div>No noise data removed</div>
                  )}
                </td>
                <td>{detail.totalRows}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {scores.length > 0 && (
        <Table striped bordered hover className="mt-3">
          <thead>
            <tr>
              <th>香水数据 1</th>
              <th>香水数据 2</th>
              <th>相似度</th>
              <th>平行坐标图</th>
            </tr>
          </thead>
          <tbody>
            {scores.map((score, index) => (
              <tr key={index}>
                <td>{score.file1}</td>
                <td>{score.file2}</td>
                <td>{score.score}</td>
                <td>
                  <Button variant="primary" onClick={() => handleShowChart(score)}>
                    点击查看
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <CosineSimilarityChart
        show={show}
        handleClose={() => setShow(false)}
        data1={chartData1}
        data2={chartData2}
        file1={file1}
        file2={file2}
      />
    </div>
  );
};

export default App;
