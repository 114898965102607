import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Legend, Tooltip } from 'recharts';

const CosineSimilarityChart = ({ show, handleClose, data1, data2, file1, file2 }) => {
  const data = data1.map((value, index) => ({
    dimension: `点 ${index + 1}`,
    [file1]: value.toFixed(4), // Change to 4 decimal places as per previous instruction
    [file2]: data2[index].toFixed(4),
  }));

  // Function to filter labels to reduce clutter
  const getTickFormatter = (tick, index) => {
    // Only show every 10th label to reduce clutter
    if (index % 10 === 0) return tick;
    return '';
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>香水鉴定可视化</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <RadarChart outerRadius={250} width={900} height={700} data={data}>
            <PolarGrid stroke="#ccc" />
            <PolarAngleAxis dataKey="dimension" tick={{ fontSize: 12 }} tickFormatter={getTickFormatter} />
            <PolarRadiusAxis angle={30} domain={[0, 1]} />
            <Radar name={file1} dataKey={file1} stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
            <Radar name={file2} dataKey={file2} stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.6} />
            <Legend />
            <Tooltip />
          </RadarChart>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CosineSimilarityChart;
